<template>
  <div class="container max-w-7xl mx-auto">
    <div class="sm:flex">
      <div class="sm:w-3/4 m-4 text-left">
        <!-- Member Directory -->
        <div class="p-4 bg-white rounded-xl shadow-md border border-light-gray">
          <h1 class="text-blue text-2xl font-sansx mb-2">Member Directory</h1>
          <div class="my-8">
            <div class="sm:flex sm:mb-4 items-end">
              <base-text-input v-model="universityCountry" name="universityCountry"
                label="Country of University attended" class="w-full sm:w-1/2 sm:px-2" />
              <base-text-input v-model="major" name="major" label="Major" class="w-full sm:w-1/2 sm:px-2" />
            </div>
            <div class="sm:flex mb-4">
              <base-text-input v-model="universityName" name="universityName" label="Name of University attended "
                class="w-full sm:w-1/2 sm:px-2" />
              <base-text-input v-model="memberName" name="memberName" label="Search Member"
                class="w-full sm:w-1/2 sm:px-2" />
            </div>
          </div>
          <BaseButton @click="handleSearch" class="w-full sm:w-1/4 my-4" :disabled="!this.loggedIn">
            <p class="font-sans">Search</p>
          </BaseButton>
        </div>
      </div>
      <!-- More useful link -->
      <div class="md:w-1/4 rounded-lg my-4 mx-2 text-left">
        <div class="shadow-lg p-4 bg-white rounded-xl">
          <p class="text-lg md:text-2xl mb-2">Membership</p>
          <div class="w-1/4 mb-2 border-b border-blue"></div>
          <div class="group py-2 cursor-pointer">
            <router-link to="/">
              <p class="font-sans group-hover:underline">Membership benefit</p>
            </router-link>
          </div>
          <div class="group py-2 cursor-pointer">
            <router-link to="/">
              <p class="font-sans group-hover:underline">Become a member</p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <!-- Member Directory Search Result -->
    <div class="text-left flex flex-col py-8">
      <!-- table header -->
      <div>
        <table class="table-auto w-full font-light">
          <thead class="border-b-2 border-light-gray">
            <tr class="text-left text-gray text-md">
              <th class="py-2 font-normal">Profile</th>
              <th class="py-2 font-normal">Name</th>
              <th class="py-2 font-normal">Phone</th>
              <th class="py-2 font-normal">Email</th>
              <th class="py-2 font-normal">Name of University</th>
              <th class="py-2 font-normal">Country of University</th>
              <th class="py-2 font-normal">Major</th>
              <th class="py-2 font-normal text-center">Social Media</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="member in members" :key="member.id" class="text-left text-base border-b border-light-gray">
              <td class="py-2 font-normal">
                <img :src="member.profile" alt=""
                  class="w-16 square-image rounded-full object-cover border-4 border-light-blue">
              </td>
              <td class="py-2 font-normal">{{ member.first_name }} {{ member.last_name }}</td>
              <td class="py-2 font-normal">{{ member.phone_number }}</td>
              <td class="py-2 font-normal">{{ member.email }}</td>
              <td class="py-2 font-normal">{{ member.name_of_university_attended }}</td>
              <td class="py-2 font-normal">{{ member.country_of_university_attended }}</td>
              <td class="py-2 font-normal">{{ member.major }}</td>
              <td class="py-2 font-normal text-center">
                <div class=" flex justify-center items-center">
                  <div v-for="(social, index) in member.social_medias" :key="index">
                    <a v-if="checkLink(social.url)" :href="`${social.url}`" target="_blank">
                      <svg v-if="social.title == 'facebook'" class="mr-4 cursor-pointer" width="24" height="24"
                        viewBox="0 0 24 24" fill="gray" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M24 12.0729C24 18.0983 19.606 23.0935 13.87 24V15.5862H16.659L17.19 12.1051H13.87V9.84649C13.87 8.89373 14.334 7.96613 15.82 7.96613H17.329V5.00222C17.329 5.00222 15.959 4.7668 14.65 4.7668C11.916 4.7668 10.13 6.43387 10.13 9.4511V12.1041H7.091V15.5852H10.13V23.999C4.395 23.0915 0 18.0973 0 12.0729C0 5.40566 5.373 0 12 0C18.627 0 24 5.40465 24 12.0729Z"
                          fill="gray" />
                      </svg>
                      <svg v-if="social.title == 'linkedin'" class="mr-4 cursor-pointer text-gray" width="24"
                        height="22" viewBox="0 0 24 22" fill="gray" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M23.002 20.584H23.229L22.794 19.926C23.06 19.926 23.201 19.757 23.203 19.55C23.203 19.542 23.203 19.533 23.202 19.525C23.202 19.243 23.032 19.108 22.683 19.108H22.119V20.584H22.331V19.941H22.592L23.002 20.584ZM22.577 19.774H22.331V19.275H22.643C22.804 19.275 22.988 19.301 22.988 19.512C22.988 19.754 22.802 19.774 22.576 19.774"
                          fill="gray" />
                        <path
                          d="M17.291 18.073H14.284V13.364C14.284 12.241 14.264 10.796 12.72 10.796C11.154 10.796 10.914 12.019 10.914 13.283V18.073H7.908V8.389H10.795V9.712H10.835C11.424 8.706 12.518 8.105 13.683 8.148C16.731 8.148 17.292 10.153 17.292 12.76L17.291 18.073ZM4.515 7.065C3.551 7.065 2.77 6.284 2.77 5.32C2.77 4.356 3.551 3.575 4.515 3.575C5.479 3.575 6.26 4.356 6.26 5.32C6.26 6.284 5.479 7.065 4.515 7.065ZM6.018 18.073H3.008V8.389H6.018V18.073ZM18.79 0.782997H1.497C0.68 0.773997 0.01 1.429 0 2.246V19.61C0.01 20.428 0.68 21.083 1.497 21.074H18.79C19.609 21.084 20.282 20.429 20.293 19.61V2.245C20.281 1.426 19.608 0.770997 18.79 0.781997"
                          fill="gray" />
                        <path
                          d="M22.603 18.451C21.839 18.458 21.225 19.084 21.233 19.848C21.24 20.612 21.866 21.226 22.63 21.2181C23.394 21.2111 24.008 20.585 24 19.821C23.993 19.067 23.383 18.458 22.63 18.451H22.603ZM22.635 21.059C21.965 21.07 21.381 20.5371 21.37 19.8671C21.359 19.1971 21.893 18.6451 22.563 18.6341C23.233 18.6231 23.785 19.157 23.796 19.827C23.796 19.834 23.796 19.84 23.796 19.847C23.81 20.502 23.29 21.045 22.635 21.059H22.604H22.635Z"
                          fill="gray" />
                      </svg>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "../components/BaseButton.vue";
import BaseTextInput from "../components/BaseTextInput.vue";
import userService from "../services/user.service";
export default {
  name: "Member Directory",
  components: { BaseButton, BaseTextInput },
  data() {
    return {
      universityCountry: "",
      major: "",
      universityName: "",
      memberName: "",
      members: [],
      params: new URLSearchParams(),
    };
  },
  methods: {
    handleSearch() {
      this.$Progress.start();
      if (this.universityCountry) {
        this.params.set(
          "country_of_university_attended",
          this.universityCountry
        );
      } else {
        this.params.delete("country_of_university_attended");
      }
      if (this.universityName) {
        this.params.set("name_of_university_attended", this.universityName);
      } else {
        this.params.delete("name_of_university_attended");
      }
      if (this.major) {
        this.params.set("major", this.major);
      } else {
        this.params.delete("major");
      }
      if (this.memberName) {
        this.params.set("search", this.memberName);
      } else {
        this.params.delete("search");
      }
      userService.getMember(this.params).then((response) => {
        this.members = response.data.data;
        this.$Progress.finish();
      });
    },
    checkLink(link) {
      if (
        link == "" ||
        link == null ||
        link == "n/a" ||
        link == "N/A" ||
        link == "NA" ||
        link == "na"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  mounted() { },
};
</script>

<style scoped>
.square-image {
  aspect-ratio: 1/1;
}
</style>