<template>
  <div>
    <div class="flex items-center justify-center h-screen bg-light-blue">
      <div class="container flex-col max-w-md content-start login-card">
        <div class="container text-left">
          <img class="w-3/5 pb-5" src="../assets/AAA-C-Logo.jpg" />
          <h1 class="text-blue text-2xl font-extrabold pb-2">Login</h1>
          <p class="text-gray">
            Please sign in with your provided Username and Password.
          </p>
        </div>
        <div class="input-form">
          <base-text-input
            v-model="email"
            name="email"
            label="Email"
            class="pb-5"
          />
          <base-text-input
            v-model="password"
            name="pass"
            type="password"
            label="Password"
            class="pb-5"
          />
          <div
            v-if="this.message"
            class="container w-full bg-light-red p-3 rounded-lg"
          >
            <p class="text-red text-left">
              {{ this.message }}
            </p>
          </div>
        </div>
        <BaseButton
          @click="handleLogin()"
          :disabled="!formIsValid"
          class="w-full"
        >
          <p>Login</p>
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTextInput from "../components/BaseTextInput.vue";
import BaseButton from "../components/BaseButton.vue";
export default {
  name: "Login",
  components: {
    BaseTextInput,
    BaseButton,
  },
  data() {
    return {
      email: "",
      password: "",
      emailIsValid: false,
      message: "",
      hideNavBar: true,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    emailNotEmpty() {
      return !!this.email;
    },
    passwordNotEmpty() {
      return !!this.password;
    },
    formIsValid() {
      return this.passwordNotEmpty && this.emailNotEmpty;
    },
  },
  created() {
    this.$emit("onAppRun", this.hideNavBar);
    if (this.loggedIn) {
      this.$router.push("/home");
    }
  },
  unmounted() {
    this.hideNavBar = false;
    this.$emit("onAppRun", this.hideNavBar);
  },
  methods: {
    handleLogin() {
      this.$Progress.start();
      this.message = "";

      const formData = new FormData();
      formData.append("email", this.email);
      formData.append("password", this.password);

      this.$store.dispatch("auth/login", formData).then(
        (response) => {
          this.$Progress.finish();
          this.message = response.message;
          this.$router.push("/");
        },
        (error) => {
          this.$Progress.fail();
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );
    },
  },
};
</script>

<style scoped>
.login-card {
  background-color: white;
  padding: 2rem;
  border-radius: 20px;
}
.input-form {
  margin: 25px 0;
}
</style>