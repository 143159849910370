export const header = {
    state: {
        headerLogo: localStorage.getItem('headerLogo') || '',
    },
    getters: {
        headerLogo: state => state.headerLogo,
    },
    mutations: {
        setHeaderLogoCache(state, headerLogo) {
            localStorage.setItem('headerLogo', headerLogo)
            state.headerLogo = headerLogo
        },
    },
}