<template>
  <div class="container md:flex max-w-7xl mx-auto h-auto">
    <!-- contenct section -->
    <div class="w-full md:w-3/4 my-4 mx-2">
      <!-- Content header -->
      <div class="text-left p-4">
        <h1 class="text-xl md:text-4xl font-semibold md:font-black mb-2">
          {{ this.privacyAndTerm.title }}
        </h1>
        <div class="w-full flex justify-between text-gray">
          <p>
            {{
              moment(this.privacyAndTerm.created_at).format(
                "MMM DD, YYYY h:mm A"
              )
            }}
          </p>
        </div>
        <div class="w-full mt-4 border-b border-light-gray"></div>
      </div>
      <!-- content body -->
      <div class="text-left p-4">
        <p v-html="privacyAndTerm.content"></p>
      </div>
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
import moment from "moment";
export default {
  name: "Privacy And Policy",
  data() {
    return {
      privacyAndTerm: [],
    };
  },
  methods: {
    getPrivacyAndTerm(slugUrl) {
      this.$Progress.start();
      userService.getPage(slugUrl).then((response) => {
        this.privacyAndTerm = response.data.data;
        this.$Progress.finish();
      });
    },
  },
  created() {
    this.moment = moment;
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    let slug_url = this.$route.path;
    slug_url = slug_url.replace("/", "");
    this.getPrivacyAndTerm(slug_url);
  },
};
</script>

<style scoped>
</style>