import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import NewsAndEvents from '../views/NewsAndEvents.vue'
import About from '../views/About.vue'
import Content from '../views/Content.vue'
import Opportunities from '../views/Opportunities.vue'
import Contact from '../views/Contact.vue'
import PrivacyAndPolicy from '../views/PrivacyAndPolicy.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import MemberDirectory from '../views/MemberDirectory.vue'
import MemberBenefits from '../views/MemberBenefits.vue'
import BecomeMember from '../views/BecomeMember.vue'
import News from '../views/News.vue'
import Events from '../views/Events.vue'

const routes = [
  {
    path: '/',
    alias: '/home',
    name: 'Home',
    component: Home,
    meta: { title: 'Home - AAA-C' }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { title: 'About Us - AAA-C' }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: 'Member Loigin - AAA-C' }

  },
  {
    path: '/news_and_events',
    name: 'NewsAndEvents',
    component: NewsAndEvents,
    meta: { title: 'News & Events - AAA-C' }
  },
  {
    path: '/content/:slug_url',
    name: 'Content',
    component: Content,
    meta: { title: 'Content - AAA-C' }
  },
  {
    path: "/opportunities",
    name: 'Opportunities',
    component: Opportunities,
    meta: { title: 'Opportunity - AAA-C' }
  },
  {
    path: '/contact_us',
    name: 'Contact',
    component: Contact,
    meta: { title: 'Contact Us - AAA-C' }
  },
  {
    path: '/privacy_and_policy',
    name: 'Privacy and Policy',
    component: PrivacyAndPolicy,
    meta: { title: 'Privacy and Policy - AAA-C' }
  },
  {
    path: '/terms_and_conditions',
    name: 'Terms and Conditons',
    component: TermsAndConditions,
    meta: { title: 'Terms and Conditions - AAA-C' }
  },
  {
    path: '/member_directory',
    name: 'Member Directory',
    component: MemberDirectory,
    meta: { title: 'Member Directory - AAA-C' }
  },
  {
    path: '/membership_benefit',
    name: 'Member Benefits',
    component: MemberBenefits,
    meta: { title: 'Member Benefit - AAA-C' }
  },
  {
    path: '/become_a_member',
    name: 'Become a Member',
    component: BecomeMember,
    meta: { title: 'Become a Member - AAA-C' }
  },
  {
    path: '/news',
    name: 'News',
    component: News,
    meta: { title: 'News - AAA-C' }
  },
  {
    path: '/events',
    name: 'Events',
    component: Events,
    meta: { title: 'Events - AAA-C' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
