<template>
  <div v-if="pageCount > 1" class="flex items-center space-x-2">
    <!-- prev button -->
    <button @click="decreasement()" :disabled="prevIsDisabled"
      class="bg-light-gray p-2 rounded-xl disabled:bg-gray">
      <ChevronLeftIcon class="w-6 h-6 text-blue disabled:text-red" />
    </button>
    <!-- page number navigator -->
    <button @click="navigateTo(page)" v-for="page in pages" :key="page" :class="[
      'px-4 py-2 rounded-xl',
      currentPage == page ? 'bg-blue text-white' : 'bg-light-gray text-blue',
    ]">
      {{ page }}
    </button>
    <!-- next button -->
    <button @click="increasement()" :disabled="nextIsDisabled"
      class="bg-light-gray p-2 rounded-xl disabled:cursor-none :disabled:bg-gray">
      <ChevronRightIcon class="w-6 h-6 text-blue" />
    </button>
  </div>
</template>
  
<script>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/outline";
export default {
  name: "Pagination",
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  data() {
    return {
      prevIsDisabled: true,
      nextIsDisabled: false,
      currentPage: 1,
    };
  },
  props: {
    pageCount: {
      type: Number,
      default: 1,
    },
    visiblePageCount: {
      type: Number,
      default: 5,
    },
  },
  emits: ["navigateToPage"],
  watch: {
    currentPage(newValue) {
      if (newValue == 1) {
        this.prevIsDisabled = true;
        this.nextIsDisabled = false;
      } else if (newValue == this.pageCount) {
        this.nextIsDisabled = true;
        this.prevIsDisabled = false;
      } else {
        this.prevIsDisabled = false;
        this.nextIsDisabled = false;
      }
    },
  },
  computed: { 
    // show page numbers in the middle of the navigator if page count is greater than visiblePageCount
    pages() {
      if (this.pageCount <= this.visiblePageCount) {
        return Array.from({ length: this.pageCount }, (_, i) => i + 1);
      } else {
        let start = this.currentPage - Math.floor(this.visiblePageCount / 2);
        let end = this.currentPage + Math.floor(this.visiblePageCount / 2);
        if (start < 1) {
          start = 1;
          end = this.visiblePageCount;
        }
        if (end > this.pageCount) {
          end = this.pageCount;
          start = this.pageCount - this.visiblePageCount + 1;
        }
        return Array.from({ length: end - start + 1 }, (_, i) => i + start);
      }
    },
  },
  methods: {
    increasement() {
      this.$Progress.start();
      this.currentPage += 1;
      this.$emit("navigateToPage", this.currentPage);
      this.$Progress.finish();
    },
    decreasement() {
      this.$Progress.start();
      this.currentPage -= 1;
      this.$emit("navigateToPage", this.currentPage);
      this.$Progress.finish();
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    navigateTo(pageNumber) {
      this.$Progress.start();
      this.currentPage = pageNumber;
      this.$emit("navigateToPage", this.currentPage);
      this.scrollToTop();
      this.$Progress.finish();
    },
  },
};
</script>
  
<style scoped>

</style>