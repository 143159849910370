import axios from "axios";

let API_URL = process.env.VUE_APP_BASE_API_URL

class AuthService {
    login(formData) {
        return axios.post(API_URL + 'login', formData).then((response) => {
            if (response.data.access_token) {
                localStorage.setItem('user', JSON.stringify(response.data))
            }

            return response.data.data;
        })
    }

    logout() {
        localStorage.removeItem('user')
    }
}

export default new AuthService();