<template>
  <div class="container max-w-7xl mx-auto">
    <!-- events -->
    <div class="md:p-8 my-1 md:mx-0 mx-6 bg-white rounded-xl text-left">
      <p class="text-2xl mb-4">All Events</p>
      <!-- events card -->
      <div class="grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 gap-4">
        <div v-for="events in eventData" :key="events.id" @click="pushToContent(events.slug_url)"
          class="group flex flex-col my-2 md:my-2 rounded-xl cursor-pointer">
          <div class="">
            <img class="w-full object-cover rounded-md md:rounded-xl img-aspect" :src="events.thumbnail_image" alt="" />
          </div>

          <div class="py-2 text-left">
            <h1 class="text-md md:text-lg group-hover:underline">
              {{ events.title }}
            </h1>
            <p class="text-sm text-gray hidden md:block mb-4 truncate-2-lines">
              {{ events.content }}
            </p>
          </div>
          <p class="
                text-sm
                md:text-md
                text-blue
                mt-auto
                cursor-pointer
                group-hover:underline
              ">
            Read More
          </p>
        </div>
      </div>
    </div>
    <!-- pagination -->
    <div class="flex justify-end my-4 pr-4">
      <BasePagination @navigateToPage="handlePagination($event)" :pageCount="metaLink.last_page" />
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
import BasePagination from "../components/BasePagination.vue";
export default {
  name: "events",
  data() {
    return {
      eventData: [],
      metaLink: [],
      params: new URLSearchParams(),
    };
  },
  methods: {
    getEvents() {
      this.params.set('type', 2);
      userService.getContent(this.params).then((response) => {
        this.eventData = response.data.data;
        this.metaLink = response.data.meta
        this.$Progress.finish();
      });
    },
    pushToContent(slug_url) {
      this.$router.push("/content/" + slug_url);
    },
    handlePagination(value) {
      this.params.set("page", value);
      this.getEvents(this.params);
    },
  },
  mounted() {
    this.getEvents();
  },
  components: { BasePagination }
};
</script>

<style scoped>
.truncate-2-lines {
  max-height: 3.6em;
  /* double the size of line-height */
  line-height: 1.8em;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}

.img-aspect {
  height: auto;
  aspect-ratio: 16/9;
  object-fit: cover;
}
</style>