<template>
  <div class="p-4 bg-blue">
    <div class="
          max-w-7xl
          mx-auto
          py-2
          sm:px-6
          lg:px-8
          items-center
          justify-between
          text-left
        ">
      <div class="divided-x"></div>
      <div class="grid sm:grid-cols-6 grid-cols-2 divide-x text-white">
        <div>
          <p class="text-white text-lg">Stay Connected</p>
          <div class="flex pt-2">
            <!-- Mail Icon -->
            <a :href="`mailto:${this.profileData.email}`" target="_blank">
              <svg class="mr-4 cursor-pointer" width="24" height="22" viewBox="0 0 24 22" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19 0H5C3.6744 0.00158786 2.40356 0.528882 1.46622 1.46622C0.528882 2.40356 0.00158786 3.6744 0 5L0 17C0.00158786 18.3256 0.528882 19.5964 1.46622 20.5338C2.40356 21.4711 3.6744 21.9984 5 22H19C20.3256 21.9984 21.5964 21.4711 22.5338 20.5338C23.4711 19.5964 23.9984 18.3256 24 17V5C23.9984 3.6744 23.4711 2.40356 22.5338 1.46622C21.5964 0.528882 20.3256 0.00158786 19 0V0ZM5 2H19C19.5988 2.00118 20.1835 2.18151 20.679 2.5178C21.1744 2.85409 21.5579 3.33095 21.78 3.887L14.122 11.546C13.5584 12.1073 12.7954 12.4225 12 12.4225C11.2046 12.4225 10.4416 12.1073 9.878 11.546L2.22 3.887C2.44215 3.33095 2.82561 2.85409 3.32105 2.5178C3.81648 2.18151 4.40121 2.00118 5 2V2ZM19 20H5C4.20435 20 3.44129 19.6839 2.87868 19.1213C2.31607 18.5587 2 17.7956 2 17V6.5L8.464 12.96C9.40263 13.8963 10.6743 14.422 12 14.422C13.3257 14.422 14.5974 13.8963 15.536 12.96L22 6.5V17C22 17.7956 21.6839 18.5587 21.1213 19.1213C20.5587 19.6839 19.7956 20 19 20Z"
                  fill="white" />
              </svg>
            </a>
            <!-- socail Icon -->
            <div v-for="(social, index) in profileData.social_link" :key="index">
              <a :href="`${social.url}`" target="_blank">
                <svg v-if="social.title === 'facebook'" class="mr-4 cursor-pointer" width="24" height="24"
                  viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M24 12.0729C24 18.0983 19.606 23.0935 13.87 24V15.5862H16.659L17.19 12.1051H13.87V9.84649C13.87 8.89373 14.334 7.96613 15.82 7.96613H17.329V5.00222C17.329 5.00222 15.959 4.7668 14.65 4.7668C11.916 4.7668 10.13 6.43387 10.13 9.4511V12.1041H7.091V15.5852H10.13V23.999C4.395 23.0915 0 18.0973 0 12.0729C0 5.40566 5.373 0 12 0C18.627 0 24 5.40465 24 12.0729Z"
                    fill="white" />
                </svg>
                <svg v-if="social.title === 'linkedin'" class="mr-4 cursor-pointer" width="24" height="22"
                  viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M23.002 20.584H23.229L22.794 19.926C23.06 19.926 23.201 19.757 23.203 19.55C23.203 19.542 23.203 19.533 23.202 19.525C23.202 19.243 23.032 19.108 22.683 19.108H22.119V20.584H22.331V19.941H22.592L23.002 20.584ZM22.577 19.774H22.331V19.275H22.643C22.804 19.275 22.988 19.301 22.988 19.512C22.988 19.754 22.802 19.774 22.576 19.774"
                    fill="white" />
                  <path
                    d="M17.291 18.073H14.284V13.364C14.284 12.241 14.264 10.796 12.72 10.796C11.154 10.796 10.914 12.019 10.914 13.283V18.073H7.908V8.389H10.795V9.712H10.835C11.424 8.706 12.518 8.105 13.683 8.148C16.731 8.148 17.292 10.153 17.292 12.76L17.291 18.073ZM4.515 7.065C3.551 7.065 2.77 6.284 2.77 5.32C2.77 4.356 3.551 3.575 4.515 3.575C5.479 3.575 6.26 4.356 6.26 5.32C6.26 6.284 5.479 7.065 4.515 7.065ZM6.018 18.073H3.008V8.389H6.018V18.073ZM18.79 0.782997H1.497C0.68 0.773997 0.01 1.429 0 2.246V19.61C0.01 20.428 0.68 21.083 1.497 21.074H18.79C19.609 21.084 20.282 20.429 20.293 19.61V2.245C20.281 1.426 19.608 0.770997 18.79 0.781997"
                    fill="white" />
                  <path
                    d="M22.603 18.451C21.839 18.458 21.225 19.084 21.233 19.848C21.24 20.612 21.866 21.226 22.63 21.2181C23.394 21.2111 24.008 20.585 24 19.821C23.993 19.067 23.383 18.458 22.63 18.451H22.603ZM22.635 21.059C21.965 21.07 21.381 20.5371 21.37 19.8671C21.359 19.1971 21.893 18.6451 22.563 18.6341C23.233 18.6231 23.785 19.157 23.796 19.827C23.796 19.834 23.796 19.84 23.796 19.847C23.81 20.502 23.29 21.045 22.635 21.059H22.604H22.635Z"
                    fill="white" />
                </svg>
              </a>
            </div>
          </div>
        </div>
        <div class="pl-4 text-white">
          <router-link to="/privacy_and_policy">
            <p class="hover:underline cursor-pointer">
              Privacy and Policy
            </p>
          </router-link>
          <router-link to="/terms_and_conditions">
            <p class="hover:underline cursor-pointer">
              Term and Condiction
            </p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-light-blue">
    <div class="
          max-w-7xl
          mx-auto
          py-2
          px-2
          sm:px-6
          lg:px-8
          items-center
          justify-between
        ">
      <p class="text-left text-blue font-sans">
        Copyright © 2022 Australian Alumni Association of Cambodia
      </p>
    </div>
  </div>
</template>

<script>
import userService from '../services/user.service';
export default {
  name: "Footer",
  data() {
    return {
      profileData: [],
      socailMedid: [],
    };
  },
  methods: {
    getProfile() {
      userService.getAACProfile().then((response) => {
        this.profileData = response.data.data
        this.socailMedia = response.data.data.social_link
        this.$store.commit('setHeaderLogoCache', response.data.data.header_logo)
      }).catch((error) => {
        if (error.response.status === 401) {
          this.$store.dispatch("auth/logout");
          this.$router.push("/home");
        }
      });
    }
  },
  mounted() {
    this.getProfile()
  }
};
</script>

<style scoped></style>