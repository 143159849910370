<template>
  <div class="container max-w-7xl mx-auto">
    <!-- events -->
    <div class="md:p-8 p-6 md:my-8 md:mx-6 mb-4 bg-light-blue md:rounded-xl text-left">
      <div class="flex justify-between items-center">
        <p class="text-2xl">Events</p>
        <button @click="hanleViewAllEvent" class="text-lg text-gray cursor-pointer hover:text-blue hover:underline">View
          more events</button>
      </div>
      <!-- card -->
      <div v-if="eventsData" class="grid md:grid-cols-4 grid-cols-1 md:gap-4">
        <div v-for="event in eventsData" :key="event.id" @click="pushToContent(event.slug_url)"
          class="group flex flex-col my-2 md:my-4 rounded-xl bg-white cursor-pointer">
          <div class="">
            <img class="rounded-t-md md:rounded-t-xl img-aspect" :src="event.thumbnail_image" alt="" />
          </div>

          <div class="py-2 px-2 md:px-4 md:py-2 text-left">
            <h1 class="text-md md:text-lg group-hover:underline">
              {{ event.title }}
            </h1>
            <p class="text-sm text-gray hidden md:block mb-4 truncate-2-lines">
              {{ event.content }}
            </p>
          </div>
          <p class="
                text-sm
                md:text-md
                text-blue
                mt-auto
                pb-2 px-2 md:px-4 md:pb-4
                cursor-pointer
                group-hover:underline
              ">
            Read More
          </p>
        </div>
      </div>
      <div v-else>
        <p class="text-red">No Events</p>
      </div>
    </div>
    <!-- news -->
    <div class="md:p-8 p-6 md:my-8 md:mx-6 mb-4 text-left">
      <div class="flex justify-between">
        <p class="text-2xl">News</p>
        <button @click="hanleViewAllNews" class="text-lg text-gray cursor-pointer hover:text-blue hover:underline">View
          more news</button>
      </div>
      <!-- find more card -->
      <div class="grid md:grid-cols-4 grid-cols-1 md:gap-4">
        <div v-for="news in newsData" :key="news.id" @click="pushToContent(news.slug_url)"
          class="group flex flex-col my-2 md:my-2 rounded-xl cursor-pointer">
          <div class="">
            <img class="rounded-md md:rounded-xl img-aspect" :src="news.thumbnail_image" alt="" />
          </div>

          <div class="py-2 text-left">
            <h1 class="text-md md:text-lg group-hover:underline">
              {{ news.title }}
            </h1>
            <p class="text-sm text-gray hidden md:block mb-4 truncate-2-lines">
              {{ news.content }}
            </p>
          </div>
          <p class="
                text-sm
                md:text-md
                text-blue
                mt-auto
                cursor-pointer
                group-hover:underline
              ">
            Read More
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
export default {
  name: "NewsAndEvents",
  data() {
    return {
      newsData: null,
      eventsData: null,
      eventParams: new URLSearchParams(),
      newsParams: new URLSearchParams(),
    };
  },
  methods: {
    getEvents() {
      this.eventParams.set('type', 2)
      this.eventParams.set('count', 4)
      this.$Progress.start();
      userService.getContent(this.eventParams).then((response) => {
        this.eventsData = response.data.data;
      });
    },
    getNews() {
      this.newsParams.set('type', 1)
      userService.getContent(this.newsParams).then((response) => {
        this.newsData = response.data.data;
        this.$Progress.finish();
      });
    },
    pushToContent(slug_url) {
      this.$router.push("/content/" + slug_url);
    },
    hanleViewAllEvent() {
      this.$router.push('/events')
    },
    hanleViewAllNews() {
      this.$router.push('/news')
    }
  },
  mounted() {
    this.getEvents();
    this.getNews();
  },
};
</script>

<style scoped>
.truncate-2-lines {
  max-height: 3.6em;
  /* double the size of line-height */
  line-height: 1.8em;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}

.img-aspect {
  height: auto;
  aspect-ratio: 16/9;
  object-fit: cover;
}
</style>