<template>
  <div class="container max-w-7xl mx-auto">
    <!-- member benefit -->
    <div class="m-4 text-left">
      <h1 class="text-blue text-2xl font-sans my-4">{{pageData.title}}</h1>
      <div class="sm:flex">
        <div class="w-full rounded-2xltext-white text-md font-sans">
          <div v-html="pageData.content">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userService from '../services/user.service';
export default {
  name: "Member Benefits",
  data() {
    return {
      pageData: [],
    };
  },
  methods: {
    getPage() {
      userService.getPage('membership_benefit').then((response) => {
        this.pageData = response.data.data
      })
    }
  },
  created() {
    this.$Progress.start()
    this.getPage()
  },
  mounted() {
    this.$Progress.finish()
  }
};
</script>

<style scoped>

</style>