<template>
  <div class="container max-w-7xl mx-auto">
    <div class="p-4 relative">
      <img
        class="rounded-xl w-full aspect object-cover img-aspect"
        :src="profileData.cover"
        alt=""
      />
    </div>
    <div class="my-4 mx-6 text-white text-left">
      <div class="w-full">
        <p
          class="sm:text-2xl text-lg text-blue mb-2 truncate-2-lines font-sans"
        >
          {{ pageData.title }}
        </p>
        <div class="text-black" v-html="pageData.content"></div>
      </div>
    </div>
    <!-- leadership section -->
    <div v-if="leaders" class="md:p-4 p-6 md:m-4">
      <!-- header -->
      <div class="text-left w-1/4">
        <p class="text-lg md:text-2xl mb-2">Our Leadership</p>
        <div class="w-1/4 mb-2 border-b border-2 border-blue"></div>
      </div>
      <!-- leadership by generation -->
      <div
        v-for="leaderSection in leaders"
        :key="leaderSection.id"
        class="text-left my-4"
      >
        <p class="text-lg md:text-2xl mb-2">{{ leaderSection.name }}</p>
        <div class="grid md:grid-cols-5 sm:grid-cols-3 grid-cols-1 md:gap-4">
          <div
            v-for="leader in leaderSection.leader"
            :key="leader.id"
            class="
              group
              flex flex-col
              bg-white
              shadow-xl
              border border-light-gray
              my-4
              rounded-xl
              text-center
              p-6
            "
          >
            <div class="flex items-center justify-center">
              <img
                class="
                  rounded-full
                  w-2/4
                  flex
                  items-center
                  justify-center
                  square-img
                  object-cover
                "
                :src="leader.user.profile"
                alt=""
              />
            </div>
            <div class="my-4">
              <p class="text-blue text-xl">
                {{ leader.user.first_name }} {{ leader.user.last_name }}
              </p>
              <p class="text-gray text-md">{{ leader.title }}</p>
            </div>
            <!-- socail Icon -->
            <div class="mt-auto">
              <div class="w-full mb-2 border-b border-light-gray"></div>
              <div class="flex items-center justify-center space-x-4 mt-auto">
                <div
                  v-for="(social, index) in leader.user.social_medias"
                  :key="index"
                >
                  <a :href="`${social.url}`" target="_blank">
                    <svg
                      v-if="social.title === 'facebook'"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24 12.0729C24 18.0983 19.606 23.0935 13.87 24V15.5862H16.659L17.19 12.1051H13.87V9.84649C13.87 8.89373 14.334 7.96613 15.82 7.96613H17.329V5.00222C17.329 5.00222 15.959 4.7668 14.65 4.7668C11.916 4.7668 10.13 6.43387 10.13 9.4511V12.1041H7.091V15.5852H10.13V23.999C4.395 23.0915 0 18.0973 0 12.0729C0 5.40566 5.373 0 12 0C18.627 0 24 5.40465 24 12.0729Z"
                        fill="#202D65"
                      />
                    </svg>
                    <svg
                      v-if="social.title === 'linkedin'"
                      width="24"
                      height="22"
                      viewBox="0 0 24 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.0021 20.584H23.2291L22.7941 19.926C23.0601 19.926 23.2011 19.757 23.2031 19.55C23.2031 19.542 23.2031 19.533 23.2021 19.525C23.2021 19.243 23.0321 19.108 22.6831 19.108H22.1191V20.584H22.3311V19.941H22.5921L23.0021 20.584ZM22.5771 19.774H22.3311V19.275H22.6431C22.8041 19.275 22.9881 19.301 22.9881 19.512C22.9881 19.754 22.8021 19.774 22.5761 19.774"
                        fill="#202D65"
                      />
                      <path
                        d="M17.291 18.073H14.284V13.364C14.284 12.241 14.264 10.796 12.72 10.796C11.154 10.796 10.914 12.019 10.914 13.283V18.073H7.908V8.389H10.795V9.712H10.835C11.424 8.706 12.518 8.105 13.683 8.148C16.731 8.148 17.292 10.153 17.292 12.76L17.291 18.073ZM4.515 7.065C3.551 7.065 2.77 6.284 2.77 5.32C2.77 4.356 3.551 3.575 4.515 3.575C5.479 3.575 6.26 4.356 6.26 5.32C6.26 6.284 5.479 7.065 4.515 7.065ZM6.018 18.073H3.008V8.389H6.018V18.073ZM18.79 0.782997H1.497C0.68 0.773997 0.01 1.429 0 2.246V19.61C0.01 20.428 0.68 21.083 1.497 21.074H18.79C19.609 21.084 20.282 20.429 20.293 19.61V2.245C20.281 1.426 19.608 0.770997 18.79 0.781997"
                        fill="#202D65"
                      />
                      <path
                        d="M22.603 18.451C21.839 18.458 21.225 19.084 21.233 19.848C21.24 20.612 21.866 21.226 22.63 21.218C23.394 21.211 24.008 20.585 24 19.821C23.993 19.067 23.383 18.458 22.63 18.451H22.603ZM22.635 21.059C21.965 21.07 21.381 20.537 21.37 19.867C21.359 19.197 21.893 18.645 22.563 18.634C23.233 18.623 23.785 19.157 23.796 19.827C23.796 19.834 23.796 19.84 23.796 19.847C23.81 20.502 23.29 21.045 22.635 21.059H22.604H22.635Z"
                        fill="#202D65"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userService from "../services/user.service";
export default {
  name: "About",
  data() {
    return {
      leaders: [],
      profileData: [],
      pageData: [],
    };
  },
  methods: {
    getLeaders() {
      userService.getLeadershipData().then((response) => {
        this.leaders = response.data.data;
      });
    },
    getProfile() {
      userService.getAACProfile().then((response) => {
        this.profileData = response.data.data;
      });
    },
    getPageData() {
      userService.getPage("about_us").then((response) => {
        this.pageData = response.data.data;
      });
    },
  },
  created() {
    this.$Progress.start();
    this.getProfile();
    this.getLeaders();
    this.getPageData();
  },
  mounted() {
    this.$Progress.finish();
  },
};
</script>

<style scoped>
.img-aspect {
  aspect-ratio: 26/9;
}
.square-img {
  aspect-ratio: 1/1;
}
</style>