import { createStore } from "vuex";
import { auth } from "./auth.module";
import { header } from "./header.module";

const store = createStore({
    modules: {
        auth,
        header,
    },
});

export default store;