<template>
  <button class="main-btn">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "BaseButton",
  props: {
    cancelButton: {
      type: Boolean,
      default: false,
    },
    greyButton: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.main-btn {
  padding: 0.8rem 1.5rem;
  border: 0;
  border-radius: 12px;
  background-color: var(--blue);
  color: white;
  transition: 0.5s;
}
.main-btn:hover:not(.main-btn:disabled) {
  cursor: pointer;
  opacity: 0.8;
  transition: 0.5s;
}
.main-btn:disabled {
  background-color: var(--dark-gray);
  color: white;
  cursor: auto;
}
</style>