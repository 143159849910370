import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./styles/app.css";
import VueProgressBar from "@aacassandra/vue3-progressbar";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import VueSocialSharing from "vue-social-sharing";
import OneSignalVuePlugin from '@onesignal/onesignal-vue3'

// configuration for vue3-progressbar
const options = {
  color: "var(--blue)",
  failedColor: "var(--red)",
  thickness: "5px",
  transition: {
    speed: "0.5s",
    opacity: "0.2s",
    termination: 900,
  },
  autoRevert: true,
  location: "top",
  inverse: false,
};

// configuariton for vue 3 toastification
const toastOption = {
  position: "top-right",
  timeout: 5000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: false,
  draggablePercent: 0.6,
  showCloseButtonOnHover: true,
  hideProgressBar: false,
  closeButton: "button",
  icon: true,
  rtl: false,
};

// configuration for OneSignal
const OneSignalConfig = {
  appId: process.env.VUE_APP_ONESIGNAL_APP_ID,
};

const app = createApp(App);
app.use(router);
app.use(store);
app.use(VueProgressBar, options);
app.use(Toast, toastOption);
app.use(VueSocialSharing);
app.use(OneSignalVuePlugin, OneSignalConfig);
app.mount("#app");
