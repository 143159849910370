<template>
  <div class="container max-w-7xl mx-auto">
    <div class="text-left my-2 p-4 sm:my-4">
      <p class="text-2xl sm:text-4xl text-blue font-sans">Opportunities</p>
      <p class="text-gray text-lg mt-1 sm:mt-2 font-sans font-light">
        We're hiring.
      </p>
    </div>
    <div v-if="!isLoading">
      <!-- opportunities card -->
      <div
        v-if="opportunities"
        class="
          grid
          md:grid-cols-3
          sm:grid-cols-2
          grid-cols-1
          gap-4
          text-left
          px-4
          pb-8
        "
      >
        <div
          v-for="opportunity in opportunities"
          :key="opportunity.id"
          class="group flex flex-col p-4 bg-light-gray rounded-lg sm:rounded-2xl"
        >
          <div>
            <h1 class="text-lg sm:text-2xl text-blue font-sans mb-2">
              {{ opportunity.title }}
            </h1>
            <p class="text-md sm:text-lg text-gray font-sans mb-4">
              {{ opportunity.content }}
            </p>
          </div>
          <BaseButton @click="pushToContent(opportunity.slug_url)" class="font-sans mt-auto md:w-2/5 w-full
          "
            >Read More</BaseButton
          >
        </div>
      </div>
      <!-- No data -->
      <div
        v-else
        class="flex items-center justify-center m-auto py-10 sm:py-20"
      >
        <inbox-icon class="h-24 w-24 text-dark-gray" aria-hidden="true" />
        <p class="text-dark-gray text-lg font-light font-sans ml-4">
          No Opportunities avaible, please check this page later for avaible
          Opportunities.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { InboxIcon } from "@heroicons/vue/24/outline";
import BaseButton from "../components/BaseButton.vue";
import userService from "../services/user.service";
export default {
  name: "Opportunities",
  components: {
    InboxIcon,
    BaseButton,
  },
  data() {
    return {
      isLoading: true,
      opportunities: null,
      params: new URLSearchParams()
    };
  },
  methods: {
    getOpportunities() {
      this.params.set('type', 3)
      userService.getContent(this.params).then((response) => {
        this.opportunities = response.data.data;
        this.isLoading = false;
        this.$Progress.finish();
      });
    },
    pushToContent(slug_url) {
      this.$router.push("/content/" + slug_url);
    },
  },
  mounted() {
    this.$Progress.start();
    this.getOpportunities();
  },
};
</script>

<style scoped>
</style>