<template>
  <div>
    <vue-progress-bar></vue-progress-bar>
    <NavigationBar v-if="!isNavBarHidden" />
    <router-view class="min-h-screen" @onAppRun="setNavBarStatus($event)" />
    <Footer v-if="!isNavBarHidden"></Footer>
  </div>
</template>
<script>
import NavigationBar from "./components/NavigationBar.vue";
import Footer from "./components/Footer.vue";
import userService from "./services/user.service";
export default {
  components: {
    NavigationBar,
    Footer,
  },
  data() {
    return {
      isNavBarHidden: false,
    };
  },
  methods: {
    setNavBarStatus(value) {
      this.isNavBarHidden = value;
    },
  },
  watch: {
    $route(to) {
      document.title =
        to.meta.title || "Australian Alumni Association of Cambodia";
    },
  },
  mounted() {
    this.$OneSignal.showSlidedownPrompt();
    this.$OneSignal.on("subscriptionChange", (isSubscribed) => {
      if (isSubscribed) {
        this.$OneSignal.getUserId().then((playerId) => {
          localStorage.setItem("player_id", playerId);
          let formData = new FormData();
          formData.append("player_id", playerId);
          userService.storePlayerId(formData);
        });
      }
    });
  },
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
