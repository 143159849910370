<template>
  <div class="container max-w-7xl mx-auto">
    <div class="sm:flex">
      <!-- contact form -->
      <div class="w-full sm:w-1/2 text-left md:px-8 px-6 sm:px-2">
        <h1 class="text-2xl sm:text-4xl text-blue font-sans py-4 md:px-2">
          Contact Us
        </h1>
        <div class="text-black" v-html="pageData.content"></div>
        <div class="form py-4">
          <div class="flex pb-4">
            <base-text-input v-model="firstName" name="firstName" label="First Name" class="w-1/2 px-2" />
            <base-text-input v-model="lastName" name="lastName" label="Last Name" class="w-1/2 px-2" />
          </div>
          <div class="flex pb-4">
            <base-text-input v-model="email" name="email" label="Email" class="w-1/2 px-2" />
            <base-text-input v-model="phone" name="phone" label="Phone Number" class="w-1/2 px-2" />
          </div>
          <base-text-input v-model="subject" name="subject" label="Subject" class="px-2 pb-4" />
          <base-text-input v-model="Comment" name="comment" label="Comment or Questions" class="px-2 pb-4 h-2/4" />
          <div v-if="message" class="p-4 bg-light-blue rounded-lg">
            <p class="text-blue">{{ this.message }}</p>
          </div>
          <div v-if="errorMessage" class="p-4 bg-light-red rounded-lg">
            <p class="text-red">{{ this.errorMessage }}</p>
          </div>
        </div>
        <BaseButton @click="handleSubmit" class="md:w-1/4 w-full font-sans">
          <p>Submit</p>
        </BaseButton>
      </div>
      <!-- Location Card -->
      <div class="w-full sm:w-1/2 text-left">
        <div class="bg-light-blue p-4 md:m-8 m-6 rounded-2xl">
          <h1 class="text-lg sm:text-2xl text-blue font-sans font-bold py-4">
            AAA-C Secretariat Office
          </h1>
          <p class="font-sans">
            {{ this.AAACprofile.address }}
          </p>
          <div v-if="this.AAACprofile.phone_number_1" class="group flex items-center my-2">
            <PhoneIcon class="w-6 h-6 text-blue mr-4" aria-hidden="true" />
            <a :href="`tel:${this.AAACprofile.phone_number_1}`" class="text-blue font-sans text-lg cursor-pointer">
              {{ this.AAACprofile.phone_number_1 }}</a>
          </div>
          <div v-if="this.AAACprofile.phone_number_2" class="group flex items-center my-2">
            <PhoneIcon class="w-6 h-6 text-blue mr-4" aria-hidden="true" />
            <a :href="`tel:${this.AAACprofile.phone_number_2}`" class="text-blue font-sans text-lg cursor-pointer">
              {{ this.AAACprofile.phone_number_2 }}</a>
          </div>
          <div v-if="this.AAACprofile.email" class="group flex items-center my-2">
            <EnvelopeIcon class="w-6 h-6 text-blue mr-4" aria-hidden="true" />
            <a :href="`mailto:${this.AAACprofile.email}`" class="text-blue font-sans text-lg cursor-pointer">
              {{ this.AAACprofile.email }}</a>
          </div>
          <div v-for="(social, index) in AAACprofile.social_link" :key="index"  class="group flex items-center my-2">
            <svg v-if="social.title === 'facebook'" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4">
              <path
                d="M24 12.0729C24 18.0983 19.606 23.0935 13.87 24V15.5862H16.659L17.19 12.1051H13.87V9.84649C13.87 8.89373 14.334 7.96613 15.82 7.96613H17.329V5.00222C17.329 5.00222 15.959 4.7668 14.65 4.7668C11.916 4.7668 10.13 6.43387 10.13 9.4511V12.1041H7.091V15.5852H10.13V23.999C4.395 23.0915 0 18.0973 0 12.0729C0 5.40566 5.373 0 12 0C18.627 0 24 5.40465 24 12.0729Z"
                fill="#202D65" />
            </svg>
            <svg v-if="social.title === 'linkedin'" width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4">
              <path
                d="M23.0021 20.584H23.2291L22.7941 19.926C23.0601 19.926 23.2011 19.757 23.2031 19.55C23.2031 19.542 23.2031 19.533 23.2021 19.525C23.2021 19.243 23.0321 19.108 22.6831 19.108H22.1191V20.584H22.3311V19.941H22.5921L23.0021 20.584ZM22.5771 19.774H22.3311V19.275H22.6431C22.8041 19.275 22.9881 19.301 22.9881 19.512C22.9881 19.754 22.8021 19.774 22.5761 19.774"
                fill="#202D65" />
              <path
                d="M17.291 18.073H14.284V13.364C14.284 12.241 14.264 10.796 12.72 10.796C11.154 10.796 10.914 12.019 10.914 13.283V18.073H7.908V8.389H10.795V9.712H10.835C11.424 8.706 12.518 8.105 13.683 8.148C16.731 8.148 17.292 10.153 17.292 12.76L17.291 18.073ZM4.515 7.065C3.551 7.065 2.77 6.284 2.77 5.32C2.77 4.356 3.551 3.575 4.515 3.575C5.479 3.575 6.26 4.356 6.26 5.32C6.26 6.284 5.479 7.065 4.515 7.065ZM6.018 18.073H3.008V8.389H6.018V18.073ZM18.79 0.782997H1.497C0.68 0.773997 0.01 1.429 0 2.246V19.61C0.01 20.428 0.68 21.083 1.497 21.074H18.79C19.609 21.084 20.282 20.429 20.293 19.61V2.245C20.281 1.426 19.608 0.770997 18.79 0.781997"
                fill="#202D65" />
              <path
                d="M22.603 18.451C21.839 18.458 21.225 19.084 21.233 19.848C21.24 20.612 21.866 21.226 22.63 21.218C23.394 21.211 24.008 20.585 24 19.821C23.993 19.067 23.383 18.458 22.63 18.451H22.603ZM22.635 21.059C21.965 21.07 21.381 20.537 21.37 19.867C21.359 19.197 21.893 18.645 22.563 18.634C23.233 18.623 23.785 19.157 23.796 19.827C23.796 19.834 23.796 19.84 23.796 19.847C23.81 20.502 23.29 21.045 22.635 21.059H22.604H22.635Z"
                fill="#202D65" />
            </svg>
            <a :href="`${social.url}`" class="text-blue font-sans text-lg cursor-pointer">
              Australian Alumni Association of Cambodia</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseTextInput from "../components/BaseTextInput.vue";
import BaseButton from "../components/BaseButton.vue";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/vue/24/solid";
import userService from "../services/user.service";
export default {
  name: "Contact",
  components: { BaseTextInput, BaseButton, EnvelopeIcon, PhoneIcon },
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      subject: "",
      Comment: "",
      message: "",
      errorMessage: "",
      AAACprofile: [],
      pageData: [],
    };
  },
  methods: {
    handleSubmit() {
      this.$Progress.start();
      let formData = new FormData();
      formData.append("first_name", this.firstName);
      formData.append("last_name", this.lastName);
      formData.append("email", this.email);
      formData.append("phone_number", this.phone);
      formData.append("subject", this.subject);
      formData.append("comment_question", this.Comment);

      userService
        .storeComment(formData)
        .then((response) => {
          this.errorMessage = "";
          this.firstName = "";
          this.lastName = "";
          this.email = "";
          this.phone = "";
          this.subject = "";
          this.Comment = "";
          this.message = response.data.message;
          this.$Progress.finish();
        })
        .catch((error) => {
          this.$Progress.fail();
          this.message = "";
          this.errorMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        });
    },
    getAAACProfile() {
      userService.getAACProfile().then((response) => {
        this.AAACprofile = response.data.data;
      });
    },
    getPageData() {
      userService.getPage('contact_us').then((response) => {
        this.pageData = response.data.data
      })
    }
  },
  created() {
    this.$Progress.start();
    this.getPageData();
    this.getAAACProfile();
  },
  mounted() {
    this.$Progress.finish()
  },
};
</script>

<style scoped>

</style>